import React from "react"
import { Container, Image, Row, Col } from "react-bootstrap"

import Image01 from "../../../images/mitgliederversammlung/2019/01.jpg"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

const Mitgliederversammlung2019 = props => {
  return (
    <Layout>
      <SEO title="Mitgliederversammlung 2019" lang="de" />
      <Container>
        <h1 className="h3">
          Mitgliederversammlung des Schützenverein 1961 Gambach e.V.
        </h1>
        <hr className="featurette-divider" />
        <small>Aus der Butzbacher Zeitung</small>
        <h6>
          Schützenverein Gambach blickt auf erfolgreiches Jahr mit zahlreichen
          Mitgliederehrungen zurück
        </h6>
        <p>
          Gambach (fs). Die Mitgliederversammlung des Schützenvereins 1961
          Gambach stand ganz im Zeichen eines ereignisreichen und aktiven Jahres
          mit Schießbetrieb, Meisterschaften und weiteren Aktivitäten. Der
          Vorsitzende Friedhelm Schwenz und sein Stellvertreter Rudolf Haas
          ehrten sieben Mitglieder für langjährige Mitgliedschaft. In seinem
          bebilderten Rückblick ließ Schwenz die geselligen Veranstaltungen des
          vergangenen Jahres noch einmal Revue passieren, wie eine
          Winterwanderung zu Beginn des Jahres, die Teilnahme am
          TSV-Volkswandern, bei dem die aktiven Sportler zusammen mit der
          Feuerwehr Gambach den ersten Platz erwanderten. Das Zusammenwirken mit
          anderen Vereinen wie beim „Oster-Fez-Ferienspaß“ der Stadt Münzenberg
          sei erfolgreich gewesen und man wolle auch dieses Jahr wieder einen
          Schnupperkurs anbieten, um die Jugend anzusprechen. Der FC Gambach
          wurde anlässlich der Gambacher Kirmes mit einem Thekendienst
          unterstützt. Michael Rheinfrank sprach er für die geleistete Arbeit
          als Jugendbetreuer seinen Dank aus. Für das Schützenhaus konnte neues
          Mobiliar in Form von Stühlen angeschafft werden und die sportlichen
          Aktivitäten konnten mit dem Erwerb eines aktuellen Pressluft-Gewehres
          verbessert werden. Die gemeinnützige Arbeit und aktive Jugendarbeit
          wurden durch die Volksbank Butzbach und die Stadt Münzenberg im Rahmen
          der städtischen Vereinsförderung unterstützt.
        </p>
        <p>
          Aus dem 23. Königsschießen ging Fabian Zeh als Schützenkönig und
          Gerhild Schäfer als Schützenkönigin hervor. Spaß am Schießsport hatten
          auch die Teilnehmer des zum 39. Mal durchgeführten
          Wanderpokalschießens, das vom Vorsitzenden als Erfolg bewertet wurde,
          der auch an der der Meldungen sichtbar wurde: 104 Teilnehmer in 24
          Teams, darunter fünf Damen-Teams. Beim traditionellen Nikolausschießen
          gab Michael Rheinfrank den bestplatzierten Schuss ab. Zum Jahresende
          nahmen einige Aktive am Preiswürfeln in Münzenberg teil.
        </p>
        <p>
          In seinem detaillierten Sportbericht erläuterte Harald Fink die guten
          Leistungen und erfolgreichen Platzierungen der vergangenen Saison.
          Hier waren gute Leistungen der zwei Luftgewehr-Mannschaften und
          Einzelschützen bei den verschiedenen Meisterschaften zu verzeichnen.
          Die erste Luftgewehr-Mannschaft schoss nach ihrem Aufstieg in der
          Oberliga Ost. Erfolgreiche Meistertitel und Platzierungen in den
          verschiedenen Klassen wurden erzielt.
        </p>
        <p>
          Die Nachwuchsschützen unterstützen inzwischen aktiv die Mannschaften
          bei den Rundenwettkämpfen. Drei neue Mitglieder konnten im
          Schützenverein begrüßt werden. In seiner Übersicht stellte Kassenwart
          Lothar Döring die geordneten Finanzen und die solide Situation des
          Vereins dar. Eine vorbildliche Führung der Kassen- und
          Buchführungsgeschäfte wurde von der Kassenprüferin Anna-Dorothea
          Becker attestiert, sie beantragte die Entlastung des Gesamtvorstandes,
          welchem die Versammlung einstimmig folgte.
        </p>
        <p>
          Unter Punkt Verschiedenes wurden Themen bezüglich des laufenden
          Vereinsjahres behandelt. Anschließend gab Schwenz noch die weiteren
          Termine für das laufende Jahr bekannt. Die Mitgliederversammlung klang
          mit einem kleinen Imbiss am Abend aus.
        </p>
        <Row className="justify-content-center">
          <Col xs={8}>
            <Image src={Image01} fluid={true} className="d-block mx-auto" />
            <small className="d-block text-center">
              Das Bild zeigt die Jubilare, die für ihre langjährige
              Vereinszugehörigkeit geehrt wurden, v.l.: Rudolf Haas (2. Vors.),
              Harald Fink und Paul Köhler (je 25 Jahre), Heike Schwab und Hans
              Jürgen Zeiß (je 40 Jahre) sowie Friedhelm Schwenz (1. Vors) (Foto:
              lw)
            </small>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default Mitgliederversammlung2019
